header {
	background:#FFF;
	border-top: 8px solid #000;
	border-bottom: 1px solid #f1f1f1;
	padding:16px 0;
	display: table;
	width:100%;

	@include mobilefirst($large-break) {
		padding:24px 0;
	}

	.container {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.brand img {
		width:100px;
	}
	.attribute {
		padding-top:14px;
		text-align:right;
		font-family: $mini-serif-bold;
		font-size: 14px;
		color: #5A5A5A;
		letter-spacing: 0px;
		@include mobilefirst($large-break) {
			font-size: 18px;
		}
	}
}
.sub-header {
	padding:20px 0;
	background:#000;
	color:#FFF;
/* 	display:none;
	@include mobilefirst($large-break) {
		display:block;
	} */
	.container {
		padding-top: 0;
		padding-bottom: 0;
	}
	.template-title {
		font-size: 20px;
		margin:0;
	}
	.utility {
		text-align:right;
	}
}