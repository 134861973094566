/*

12 column grid with mobile-first layout
4 available breakpoints: $small-break, $medium-break, $large-break, $x-large-break (the numeric values of each are set in _config)

Example usage - these 2 columns (mobile-first stacked) will go horizontal (float) at the $medium-break breakpoint value:
<div class="container">
    <div class="row">
        <div class="span-medium-6"></div><div class="span-medium-6"></div>
    </div>
</div>

*/

.container{
    position: relative;
    max-width: $container-max-width;
    margin: 0 auto;
    padding:$padding;
    @include mobilefirst($small-break) {
        padding:$padding*1.4;
    }
    @include mobilefirst($medium-break) {
        padding:$padding*2;
        &.pull-up {
            position: relative;
            top:-100px;
        }
    }
}


.main {
}
.main .container {
    padding:0;

    @include mobilefirst($small-break) {
        padding:$padding*1.4;
    }
    @include mobilefirst($medium-break) {
        padding:$padding*2;
    }
}

.primary-span {
    
    .primary {
        padding:20px 20px 0 20px;
        @include mobilefirst($medium-break) {
            padding:0 60px 0 0;
        }
    }

    h1 {
        margin-bottom:30px;
        @include mobilefirst($medium-break) {
            margin-bottom:40px;

        }
        
    }
}

.tertiary-span {
    
    margin: 0;
    @include mobilefirst($medium-break) {
        margin-bottom:20px;
    }

    .tertiary {
        padding:0;
        background:#EEE;

        @include mobilefirst($medium-break) {
            margin: 0;
            position: static;
        }

    }

}

.row {
    margin-right:-$padding;
    margin-left:-$padding;
    @include clearfix;
}

@for $i from 1 through 12 {
    .span-#{$i},
    .span-small-#{$i},
    .span-medium-#{$i},
    .span-large-#{$i},
    .span-x-large-#{$i} {
        padding-left:$padding;
        padding-right:$padding;
        margin-bottom:$padding;
        @include clearfix;
    }
}


@for $i from 1 through 12 {
    .span-#{$i} {
        float:left;
        width: unquote(100/(12/$i)+'%');
        margin-bottom:0;
    }

}

@include mobilefirst($x-small-break) {

    @for $i from 1 through 12 {
        .span-x-small-#{$i} {
            float:left;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
    }

}

@include mobilefirst($small-break) {

    @for $i from 1 through 12 {
        .span-small-#{$i} {
            float:left;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
    }

}

@include mobilefirst($medium-break) {

    @for $i from 1 through 12 {
        .span-medium-#{$i} {
            float:left;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
        .span-medium-#{$i}.reversed {
            float:right;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
    }

}

@include mobilefirst($large-break) {

    @for $i from 1 through 12 {
        .span-large-#{$i} {
            float:left;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
    }
}

@include mobilefirst($x-large-break) {

    @for $i from 1 through 12 {
        .span-x-large#{$i} {
            float:left;
            width: unquote(100/(12/$i)+'%');
            margin-bottom:0;
        }
    }
}