// normalize using Normalize
@import "normalize";

// Fonts: Font Awesome (4.4.0) included by default
@import "fonts";

// Set variables and reusable mixins
@import "config";
@import "mixins";

// Import typical layout sass modules
@import "typography";
@import "grid";

@import "template";

//@import "type";
//@import "media";
@import "header";
@import "footer";
@import "links";
//@import "tables";
@import "forms";
@import "modal";

/* @import "payment_estimator";
@import "credit_application"; */

// IE support is needed
@import "ie";

// Print styles
@import "print";

// For shameful hacks (should be empty ಠ_ಠ )
//@import "shame";


.hero.payment-estimator {
	background-image:url('../images/hero--payment-estimator.jpg');
}
.sub-header {
	position: relative;

	.print-init {
		position: absolute;
		right:10px;
		top:-8px;
		@include mobilefirst($small-break) {
			top:0;
			right:30px;
		}
		@include mobilefirst($medium-break) {
			top:0;
			right:40px;
		}
	}
} 
.print-init {
	color:#999;
	text-decoration:none;
	font-family:$mini-sans;
	background:#333;
	padding:8px 8px 6px 8px;
	border-radius:6px;
	i {
		margin: 0;
	}
	span {
		display:none;
	}
	@include mobilefirst($small-break) {
		background:none;
		padding:0;
		span {
			display:inline-block;
		}
		i {
			margin-right:6px;
		}

	}
	&:hover {
		color:#FFF;
	}
}
.step {
	border-bottom:1px solid #CCC;
	padding:20px 0 10px 0;
	margin-bottom:40px;
	@include mobilefirst($x-small-break) {
		padding:30px 0 0 0;
		margin-bottom:10px;
	}
	@include mobilefirst($small-break) {
		padding:30px 0;
	}
	&:last-of-type {
		border:none;
		padding-bottom:0;
	}
	.step-title {
		overflow:hidden;
		position: relative;
		.group {
			float:left;
			padding-left:70px;
		}
		h3 {

			font-size: 31px;
			color: #3B3B3B;
			margin: 0;
		}
		.step-numeric {
			position: absolute;
			left:0;
			top:0;
			background-color: #000;
			display: inline-block;
			padding:10px 20px;
			border-radius: 50%;
			color:#FFF;
			font-size: 28px;
			
		}
		p {
			font-size: 21px;
			color: #555555;
			line-height: 27px;
			margin: 0;
		}
	}
}

.optional-details h4 {
	margin: 0;
	@include mobilefirst($medium-break) {
	margin-bottom:30px;
}
}


.options {
	position: relative;
	
	@include mobilefirst($medium-break) {
		margin-top:-110px;
		z-index:200;
		padding:40px 0 0 0;
	}

	background:#EEE;
	
	.row, .container {
		overflow: visible !important;
	}
	.option {
		background:#FFF;

		h4.option-title {
			padding:40px 30px 0px 30px;
			@include mobilefirst($medium-break) {
				position: relative;
				top:-40px;
				padding:0;
				margin: 0;
	    	}
	    }


		.form {
			padding:0 20px 0px 20px;
			@include mobilefirst($medium-break) {
				padding:10px 30px 0px 30px;
				min-height:440px;
			}
		}
		.estimated-monthly-payment {

			position: relative;
			@include mobilefirst($medium-break) {
				padding:40px;
			}
			border:1px solid #EEE;
			border-left:none;
			border-right: none;

			em {
				position: relative;
				top:-4px;
				margin-right: 10px;
				font-size: 40px;
				color: #B5B5B5;
				font-style: normal;
			}
			span.payment-total {
				display:inline-block;
				font-size: 50px;

				color: #555555;
			}
			div {
				font-size: 16px;
				color: #AAAAAA;
				padding-left:30px;
				font-family:$mini-sans;
			}
		}
		.cta-s {
			padding:20px;
			@include mobilefirst($medium-break) {
			padding:40px;
		}
			.button {
				display: block;
				margin-bottom: 10px;
			}
		}
	}
}

.details-toggle-init {
	display:block;
	@include mobilefirst($medium-break) {
		display:none;
	}
	padding:20px;
	text-align:center;
	border-top:1px solid #EEE;
	font-family:$mini-sans;
	text-transform: uppercase;
	text-decoration:none;
	color:#555;
	i {
		color:#999 !important;
		margin-right:10px;
	}
	i:before {
		content:'v';
	}
	span:before {
		content:'Edit';
	}
	&.active i:before {
		content:'u';
	}
	&.active span:before {
		content:'Hide';
	}

}

.vin {
	color:#777;
	padding:15px 10px;
	font-size:24px;
	font-family:$mini-sans;
	border:2px solid #EEE;
	text-align:center;

	@include mobilefirst($medium-break) {
		font-size:18px;
	}

	@include mobilefirst($x-large-break) {
		font-size:24px;
	}
}