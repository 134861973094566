a[href], button { touch-action: manipulation; }

.button {
	display:block;
    border: 1px solid;
    cursor: pointer;
    padding: 20px 10px;
    text-align: center;
    transition: all 250ms ease-in-out;
	text-transform:uppercase;
	text-decoration: none;
	font-family: $mini-sans-bold;

    .icon {
        margin-right:10px;
    }
    @include mobilefirst($medium-break) {
        display:inline-block;
        padding: 20px;
    }
    &.disabled {
        opacity:.3;
        cursor:default;
    }
}

.button--primary,
.button--primary:active,
.button--primary:visited {
    background-color: $brand-red;
    border-color: $brand-red;
    color: #fff;
}
.button--primary:not(.disabled):hover {
    background-color: transparent;
    border-color: #000;
    color: #000;
}
.button--secondary,
.button--secondary:active,
.button--secondary:visited {
    border-color: #000;
    color: #000;
}
.button--secondary:not(.disabled):hover {
    border-color: $brand-red;
    color:$brand-red;
}
.button--tertiary,
.button--tertiary:active,
.button--tertiary:visited
 {
    background-color: #DDD;
    border-color: #DDD;
    color: #000;
}
.button--tertiary:not(.disabled):hover {
    border-color: $brand-red;
    color:$brand-red;
}
.button--passive,
.button--passive:active,
.button--passive:visited
 {
    border-color: #999;
    color: #999;
}
.button--passive:not(.disabled):hover {
    border-color: $brand-red;
    color:$brand-red;
}

.recalculate {
    display: inline-block;
    width:38px;
    height:38px;
    border:1px solid #CCC;
    // border-radius: 50%;
    @include border-radius();
    background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAABGdBTUEAALGPC/xhBQAABNZJREFUWAntmFtoXEUYx7OXpLsmJg9tQrXGBzW6mJLLJqgg2KpgvWAVAxLQR6FSKzQFkbYPigGp+uQFsbWCT+KDikpjVdBWrBRtLhvcTaoppiWUNmtjcJeQvSXr7zvuHs85mbM7J40g2IFvZ+a7zX++mflmztbU/EeLbzW4isViIBaLRZeXl7difyPUDG2ACtAF5Bf9fv8Z2kej0eg5as/FE7Dx8fHNhUJhDwP3MVKj5mhx9D6tra19s7OzM+lmMzw8fBWyaFNT06m2trasFrCxsbE2ovM6gB5wc1yN7/P5FqA3gsHgax0dHfNW/dHR0Wvx/w28CPR1b2/vNr9VwdkGiA+jZ5eWlmKXA0r8Yl/P4HtzudzUyMjI/eWx2BKbkB2nL6BqAL/VqOVHVRKJRF0mk/kAI1m2NS0MvozDl6jfL0VK9mm55IlYnXIpp6enQ3Nzcx+j+WBZ+1+qM/gNOXwbwIIOptEF1Ic0dED9wKy/Q/csp/As0ZVItBCFm6i3QbdDyjHgS3GC+pvL7woj1n+AAR4xNVY28oA5GAgE3unq6kqsFJucQdk/7M99cJ7CZ50p0WjYlhJH3aSDH7GrdbH9DUD93d3dp1zkSrakmXw+L1vjZqWCnWkspe1UAuoAOkpQROkrZFGvoGRM8lecPLadpiRgrWIuJbnqTsJ+n4vV6cbGxsdJfCkXeUU2yfMaIvYZSuZ4FQ0QmhFjwz7vopxito9eDij8HoNucfGvZBvApqam5Hpxi9YgS/GL0roKk0jJ/ekZlLg1gKXT6Yc4NesU46QaGhoOKvhaLPblDhQ9RQr9JXFuAGMZO6XjLDg+FIlE0k6+bh/7E1BOV7+k94nURrpgKdelUqnNODHTBxEshkKhn9vb2706tuHgatuYzWavszFdOqSiDBf8BDAkUV8pVyLw/4yAcQp5UTzBIXyaEGi9ADg18ZaWll2tra2Lqw0bL+N+0tRu7M1MgN8sT++dcrcGAeQH2LsohHUHwea22dnZk+gf1rWx6mEfYMxX4F3v4NcAtkl4wYmJCblYtUGJETPL8TAclvZqCqAew84GquRnljeeTPifS7wkqFoJKJT6eP7EqiorFGZmZsL4eFEhkgl/DhnJ1XxdqBSdvDKonp6eI06Zbj+ZTB5iKW9V6ZP5za2hDWwtQLGEuwH1pAoUvC9Yxp/KMu2HGwZBwHXheIi6WHagU/P8kVfxAWz3qPTFH/SCVaYdMZz6OTGDzPpLjrryNWJ1XG6j30H7e0gJSvTw/Sr/cdgOk08+bBcXF7OioFtKERvC4Ue8bo9a/5OQCcTj8U18cd+Lvx3076jkF18nAXUXdcGqtypgVgel9p/UFyBJ0K2Q8oMGvrP8SkK9h7113ilQLiXoc9B+KOk0cOlLUoxAN0C6oBLhcHiLCpSMsQKYgILfR0p4meMbpS3fmWtaGONIfX39Fh6hF90c+9gD8o9OirrBAsrMU7IHeYHuZ+MP4ORqN0ea/DQ3xgB76r1q+sYFCrDtDNwPsMNE6luV0eTk5PqFhYXn0HlGJqHSqcC7BKC3kb8FqN8r6Jki82Y3OVUa8k/Q/Pz83UzkYVTlk0/uPNu+ArxcK6eh47SPNTc3D3l9iXgGxmC2IluBXLWetLGRr2058peI+h8lcDZdL52/ALys5XNLoanxAAAAAElFTkSuQmCC') no-repeat center center;
    background-size:50%;
    @include hide-text;
    @include translate-center(y);
    right:10%;
}
