@media print {
	.hero,
	a.button,
	.details-toggle-init,
	.recalculate,
	.error-message,
	.help,
	.modal-content
	{display:none !important;}
	
	.details {
		display:block !important;
	}
}