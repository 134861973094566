$mini-sans: 'MiniSans',Times,"Times New Roman",Georgia,serif;
$mini-sans-bold: 'MiniSansBold',Times,"Times New Roman",Georgia,serif;
$mini-serif: 'MiniSerif',Times,"Times New Roman",Georgia,serif;
$mini-serif-bold: 'MiniSerifBold',Times,"Times New Roman",Georgia,serif;
$mini-icons: MiniIcons;

body {
	font-size:16px;
	font-family:$mini-serif;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale
}
body strong{
	font-family:$mini-serif-bold;
}

h1,h2,h3,h4,h5,h6 {
    line-height: 1;
    font-family: $mini-serif;
	font-weight:normal;
	margin:0 0 30px 0;
	
	&.mini-dash:after {
	    content: '';
	    display: block;
	    width: 50px;
	    border-bottom: 2px solid;
	    margin: 20px 0;
	    @include mobilefirst($medium-break) {
			margin: 20px 0;
	    }
	}
}
h1 {
	text-transform: uppercase;
	
	font-size: 28px;
	line-height:28px*1.3;

	@include mobilefirst($small-break) {
		font-size: 38px;
		line-height:43px;
	}
	@include mobilefirst($medium-break) {
		font-size: 50px;
		line-height:55px;
	}


}
h2 {
	font-size:24px;
	margin: 0 0 20px 0;
	@include mobilefirst($medium-break) {
		font-size:38px;
	}
}
h4 {
	/* Optional Details:: */
	font-size: 18px;
	@include mobilefirst($medium-break) {
		font-size: 21px;
	}

	color: #555555;
	line-height: 27px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}

p {
	margin:0 0 20px 0;
	line-height:25px;

	&.dim {
		color:#777;
	}
}
ul,ol,dl {
	line-height:25px;
	margin:0 0 30px 0;
	padding:0 0 0;
	li {
		padding:0 0;
		margin:0 0 10px 17px;
		@include mobilefirst($medium-break) {
			margin:0 0 10px 34px;
		}
	}
}
dl {
	dt {
		font-weight:bold;
	}
	dd {
		margin: 0 0 14px 0;
	}
}
.modaal-content h3 {
	font-size:18px;
	/* border-top:1px solid #CCC; */
	padding-top:20px;
	@include mobilefirst($medium-break) {
		font-size:28px;
	}
}
.modaal-content h4 {
	font-family:$mini-sans-bold;
	font-size:18px;
	color:#000;
	margin: 0;
}
.modaal-content * {
	font-family:$mini-sans;
}