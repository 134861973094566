@font-face {
	font-family:'MiniSans';
	src:url("../fonts/mini_sans_serif-regular-webfont.eot");
	src:url("../fonts/mini_sans_serif-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/mini_sans_serif-regular-webfont.woff2") format("woff2"),
		url("../fonts/mini_sans_serif-regular-webfont.woff") format("woff"),
		url("../fonts/mini_sans_serif-regular-webfont.ttf") format("truetype"),
		url("../fonts/mini_sans_serif-regular-webfont.svg#svgFontName") format("svg")
}

@font-face {
	font-family:'MiniSansBold';
	src:url("../fonts/mini_sans_serif-bold-webfont.eot");
	src:url("../fonts/mini_sans_serif-bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/mini_sans_serif-bold-webfont.woff2") format("woff2"),
		url("../fonts/mini_sans_serif-bold-webfont.woff") format("woff"),
		url("../fonts/mini_sans_serif-bold-webfont.ttf") format("truetype"),
		url("../fonts/mini_sans_serif-bold-webfont.svg#svgFontName") format("svg")
}

@font-face {
	font-family:'MiniSerif';
	src:url("../fonts/mini_serif-regular-webfont.eot");
	src:url("../fonts/mini_serif-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/mini_serif-regular-webfont.woff2") format("woff2"),
		url("../fonts/mini_serif-regular-webfont.woff") format("woff"),
		url("../fonts/mini_serif-regular-webfont.ttf") format("truetype"),
		url("../fonts/mini_serif-regular-webfont.svg#svgFontName") format("svg")
}

@font-face {
	font-family:'MiniSerifBold';
	src:url("../fonts/mini_serif-bold-webfont.eot");
	src:url("../fonts/mini_serif-bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/mini_serif-bold-webfont.woff2") format("woff2"),
		url("../fonts/mini_serif-bold-webfont.woff") format("woff"),
		url("../fonts/mini_serif-bold-webfont.ttf") format("truetype"),
		url("../fonts/mini_serif-bold-webfont.svg#svgFontName") format("svg")
}

@font-face{
	font-family:'MiniIcons';
	src:url("../fonts/miniicons-regular-webfont.eot");
	src:url("../fonts/miniicons-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("../fonts/miniicons-regular-webfont.woff2") format("woff2"),
		url("../fonts/miniicons-regular-webfont.woff") format("woff"),
		url("../fonts/miniicons-regular-webfont.ttf") format("truetype"),
		url("../fonts/miniicons-regular-webfont.svg#svgFontName") format("svg")
}
