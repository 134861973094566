// Consistent settings
$br: 4px; // Border-radius
$btn-br: 4px; // Button border-radius
$padding: 20px; // Padding and margins
$line-height: 44px; // Consistent height for buttons, inputs etc.

$container-max-width:1280px;

// Responsive breakpoints matches bootstraps breakpoints
$x-small-break: 330px;
$small-break: 768px;
$medium-break: 980px;
$large-break: 1024px;
$x-large-break: 1280px;

// Colors
// http://davidwalsh.name/sass-color-variables-dont-suck

$brand-red: #ac2430;
$ocean-blue: #254E87;
$midnight-blue: #151B2B;
$neon-blue: #3D7CD4;
$sky-blue: #E6FCFF;
$baby-blue: #85C0D5;

$purple: #3C3B8F;
$teal:  #00DBBB;
$white-teal: #EFFCF3;

$snow:#F3F3F3;
$fog:#EEE;
$ash: #CCC;
$aluminum:#999;
$lead: #555;
$coal: #333;

