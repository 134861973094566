footer 
{
	padding:30px 0;

	.brand-line {
		margin-bottom:40px;
		h5 {
			margin-bottom:4px;
		}
		p {
			color:#333;
		}
	}

	.legal {
		font-family:$mini-sans;
		font-size: 13px;
		color: #999999;
		line-height: 17px;

		h6 {
			color: #5A5A5A;
			font-family:$mini-sans;
			color: #999999;
			margin: 0 0 8px 0;
		}
	}
	a.button--secondary {
		border-color:#999;
		color:#999;
	}
}