// on behalf of the js
#prefill-toggle,
#trade-in-toggle,
#co-applicant-toggle-disclaimer,
#co-applicant-toggle,
#visa-toggle,
#co-visa-toggle,
#options-1-details-toggle,
#options-2-details-toggle,
#options-3-details-toggle {
	display:none;
}

@include mobilefirst($medium-break) {
	#options-1-details-toggle,
	#options-2-details-toggle,
	#options-3-details-toggle
	{
		display:block;
	}
}

html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
img {
  display:block;
  width:100%;
}
body {
	background: #EEE;
}
hr {
	border:none;
	background:#000;
	width:100%;
	display:block;
	margin-bottom:60px;
}
.modal-content {
	display:none;
}
.hero {

	padding-bottom:30%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
.main {
	background:#FFF;
	.container {
		background:#FFF;
		&.offset-top {
			@include mobilefirst($large-break) {
				top:-100px;
			}
		}
	}
}
.intro {
	@include mobilefirst($large-break) {
		padding:40px 70px;
	}
	p {
		color: #555555;
		line-height: 27px;
	}
}

.icon {
	font-family:$mini-icons !important;
	font-weight:normal !important;
	font-style: normal !important;
	display: inline-block;
	text-transform:none !important;
	&.align-right {
		float:right;
		margin-left:20px;
	}
}
.icon--help {
}
.icon--edit {
}
.icon--search {
}
.icon--print {
background:	url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAA8CAYAAAA5S9daAAAABGdBTUEAALGPC/xhBQAAA05JREFUaAXtWr9r20AUrmSbTq0htJAh0NC5k02GzF3araRTuzn4x9qh9I8oHbMYm3pLoUPoUggNZE2guFPn0kK3DMZ0KsJyv2ddjCQO653kqCf5Hcine/frfZ/eu3s6y7llkAaDQXM+n79Flz3kdwy6Zmk6RecfjuOcuq573G63v2cZTNfX0Ql1MiLA9/0L1NV09XnIQMQc85zUarXXrVbr57rmdLkDKQv4bwSQntDBwfXc87zxcDh8zNU9qR2bBAy0lzRYXvUgYms2m30GEU/WMSebBEyc1xrAxXUbRHxaBxHsNaHf75M/RlKv12P3j3Q0KOjmjXX/W6lUnmHBPI3J2UW2JbBHzL9hZosoAwlEeyYiykJCmAjjXaNMJCyIQCzzcTQa7VKBm8pGAsUSW4gj3nEJoHbWk4Ao8Y8JINX2AFvnI24/60kAkK9cMNftYA0O3OLldTkpt54EWMIbgPCSgGjqn2pkWpH1JHQ6nTHeHvdBxrmJa8AaHmoRa4RVjcw6EREBpVZufZrI8i4XiPWWwAWSpZ2QAPaEBCEhcCKxBLEEsYSAAbEEsYSlJUQiRoSaLv5feIFaevmgP1ruIy/s4hmKIn2E3FfAMkb+AeeRx8h9lBdpeVCKV88HOL09gbSh6hKzPA5aE5VQDUKAOV2+4XD2AGT8osaLp6wIuESZTQBnJovbNPDALwk36eiSCygL2LZY6ZtQbZtwE34XbJD/b4oFxMlsEP4qmKCFMFUy9MNUc2TphMXvN67Der1+MZ1O94H1Pa6d8JiEn3aHZlhYpnsiAGcRZwrTGXa+Q4D+EsPYpDWBtsFSJrKAMLB4WdXdo92hsHFAGKDunlwgLI+XqQ5GUHFs9+swCNN7zppAY0YiRtNJbG+Pp7xDa8BkMlmpamldYSXqWKWQAEKEBCEh8AuxBLEEsYSAAbEEsYSlJWSKGAt8vLYkgG5kdxASAoNw8aY1C24385fwkzscbSoRCvdRtdvtvgIRdK1MRTx34C7csjDi0QsJQkKwAoglgIdMEWMRF8vg2Ud/2ZaA7STNh9bR2XIsmejLJgH6G39onSNm3VRsfdkkgNm0H1rrFLxpmaf0Zc3DJiHth9YsLdbUiFwA1zl9EK6+h2aN/A9GFhx0xj9FWgAAAABJRU5ErkJggg==') center center no-repeat;
width:16px;
height:14px;
background-size:16px;
margin-right:4px;
}

div.align-right {
	text-align:right;
}

.tabs li {
	display:block;
	list-style:none;
	padding: 0;
	margin: 0;
	@include mobilefirst($medium-break) {
		display:inline-block;
	}
}
.tab {
	display:block;
	padding:10px;
	border:1px solid #DDD;
	text-decoration:none;
	color:#777;

	@include mobilefirst($medium-break) {
		padding:10px 30px;
	}

	&.active {
		color:#000;
		border-color:#000;
		&:after {
			content:'viewing';
			text-transform: uppercase;
			@include border-radius(4px);
			background:#EEE;
			float:right;
			display:inline-block;
			font-size:12px;
			padding:0 6px;
		}
		@include mobilefirst($medium-break) {
			&:after {
				display:none;
			}
		}
	}
}
.tab-content {
	display:none;

	&.active {
		display:block;
	}
}
.application-error {
	padding:60px 14%;

	@include mobilefirst($medium-break) {
		min-height:400px;
	}
	i {
		color:#777;
		font-size:60px;

		@include mobilefirst($medium-break) {
			font-size:140px;
		}
	}
	h1 {
		margin:0;
	}
	p {
		font-size:24px;
		line-height:35px;
	}
}