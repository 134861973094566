// Clear after floats
@mixin clearfix {
  zoom:1;
  &:before, &:after {
    content: "\0020"; 
    display: block; 
    height: 0; 
    overflow: hidden; 
  }
  &:after {
    clear: both;
  }
}

// Apply clearfix to these classes by default
.clearfix, .container{
  @include clearfix;
}

@mixin mobilefirst($res)
{
  @media screen and (min-width: $res)
  {
    @content;
  }
}

// Image replace text
@mixin hide-text() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin transition($transition) {
  -webkit-transition: $transition;  
  -moz-transition:    $transition;
  -ms-transition:     $transition; 
  -o-transition:      $transition;
  transition: $transition;
}

@mixin border-radius($radius:50%) {
  -webkit-border-radius:$radius;
  -moz-border-radius:$radius;
  border-radius:$radius;
}


@mixin translate-center($axis: "both"){
    position:absolute;
    @if $axis == "y"{
        top:50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @if $axis == "x"{
        left:50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    @if $axis == "both"{
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}
