.form {
    position: relative;
}

.help {
    color:#CCC;
    font-size:18px;
}
.help:hover .icon--help {
    color:#333;
}

.error {
    input, select, label{
        border-color:$brand-red !important;
    }
    label{
        color:$brand-red;
    }
    .error-message {
        font-family:$mini-sans;
        font-size:14px;
        color: $brand-red;
        margin-bottom: 0.5em;
        padding-top: 0.25em;
        position: relative;
        transition: all 300ms ease-in-out;
    }
    &.banner {
        background:$brand-red;
        color:#FFF;
        padding:20px;
        font-family:$mini-sans;
        margin-bottom:20px;
        i {
            margin-right: 10px;
        }
    }
}

.information {
    font-size:14px;
    margin-bottom: 20px;
    color:#999;
    font-family: $mini-sans;
    i {
        color:#CCC !important;
        margin-right:8px;
    }
    @include mobilefirst($medium-break) {
        position: absolute;
        top:6px;
        right:0;
        padding: 0;
        margin: 0;        
    }
}

fieldset {
    border:none;
    padding:0;
    margin: 0 0 20px 0;
    @include mobilefirst($medium-break) {
        padding-bottom:10px;
        margin-bottom:30px;
    }
    legend {
        font-size:24px;
        float:left;
        padding-bottom:14px;
        margin-bottom:20px;
        border-bottom:1px solid #DDD;
        width:100%;
        @include mobilefirst($medium-break) {
            margin-bottom:40px;
            padding-bottom:14px;
        }
        span.denote {
            text-transform: uppercase;
            float:right;
            background:#EEE;
            font-size:12px;
            display: inline-block;
            margin-top:3px;
            padding:4px 6px;
            color:#777;
            font-family:$mini-sans;
        }

    }
}

label {
    font-family: $mini-sans-bold;
    text-transform: uppercase;
    display: block;
    color:#555;
    font-size: 13px;
    line-height: 1.462;
    margin-bottom: 0.5em;
    position: relative;

    .help {
        @include translate-center(y);
        right:0;
    }
    span.denote {
        text-transform: uppercase;
        float:right;
        background:#EEE;
        font-size:12px;
        display: inline-block;
        padding:1px 6px;
        color:#777;
        font-family:$mini-sans;
    }
}
.form h5 {
    margin:20px 0 10px 0;
    @include mobilefirst($medium-break) {
        margin: 0 0 20px 0;
    }
}
.radio label {
    font-size:inherit;
    font-family:$mini-serif;
}


.disclaimer {
    font-family: $mini-sans;
    font-size: 13px;
    color: #777777;
    line-height: 19px;
    margin:14px 0 10px 0;
}
form .disclaimer {
    @include mobilefirst($medium-break) {
        width:60%;
    }
}


button, input, select, textarea {
    -webkit-appearance: none;
    border-radius: 0;
    vertical-align: baseline;
    *vertical-align:middle;
}

input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="tel"], select, textarea {
    margin: 0;
    width: 100%;
    font-family: $mini-sans;
    font-size: 20px;
    line-height: 1.4;
    border: 1px solid #a9a9b0;
    padding: .7em 1em;
    background: #fff;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
   }

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    left: -9999px;
}

input[type="checkbox"]+label,
input[type="radio"]+label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;
}

// A specially treated, structured assembly of inputs
.input-assembly {
    
    .input-assemblage {
        margin-bottom:20px;
        select {
            background-position:85%;
            padding-right:60px;
        }
        @include mobilefirst($medium-break) {
            float:left;
            margin:0 14px 0 0;
            select {
                width:auto;   
            }
        }
    }
}


.input-group [type="checkbox"]+label:before,
.input-group [type="radio"]+label:before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background: #fff;
    position: absolute;
    top:-4px; 
    font-family:$mini-icons !important;
    font-weight:bold !important;
    font-style: normal !important;
    font-size:10px;
    padding:2px 0 0 1px;
    display: inline-block;
    text-transform:none !important;
}


.input-group [type="checkbox"]:checked + label:before,
.input-group [type="radio"]:checked + label:before {
    content:'y';
}

.input-group [type="checkbox"]+label:before,
.input-group [type="radio"]+label:before {
    position: absolute;
    left: 0;
    top:1px;
}
.input-group {
    @include clearfix;
    clear:both;
	margin-bottom:20px;

    @include mobilefirst($medium-break) {
        margin-bottom:30px;
    }
	.input, select {
	    background-position: 96% center;
	    background-repeat: no-repeat;
	}

	select {
        padding-right:40px;
	    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAQAAAAeXLZtAAAApklEQVQYGQXBP0qCAQAH0OcXpE16AFHaRfEEguAVRAfPEISDZ2jIxTMIQgjtBQpC4TUkW/szpeCv93iydwsAAACa3j3zIj51AQAAdBzFlqpX8WsAAAD6fsRGDa4txdkEAMDYSayUAQoPImZKAEqmIh4VAMCdi1i4AoW5iHsAABj6E2s3KlbiZAQAANDzJXY24lsfAAAAWg4iPrQBAAAA6t7sNQAA/gF4yDLDupe02QAAAABJRU5ErkJggg==");
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	}
}

.input-group.checkbox label {
    font-size:16px;
    font-family:$mini-serif !important;
    text-transform: none;
    @include mobilefirst($medium-break) {
        font-size:18px;
    }
}

.input--dollar-amount{
    input {
        padding-left:30px;
    }
    position: relative;
    &:before {
        content:'$';
        @include translate-center(y);
        left:12px;
        font-size:21px;
        font-family:$mini-sans;
        z-index:999;
        color: #AAAAAA;
    }
}

.input--percentage-amount{
    input {
        padding-left:34px;
    }
    position: relative;
    &:before {
        content:'%';
        @include translate-center(y);
        left:12px;
        font-size:21px;
        font-family:$mini-sans;
        z-index:999;
        color: #AAAAAA;
    }
}
